
import { Actions } from "@/store/enums/ProductSuppliersEnums.ts";
import { Actions as SupplierActions } from "@/store/enums/SupplierEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
 export default {
   name : 'EditProductSupplier',
   props : {
     supplier : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const mySupplier = reactive(JSON.parse(JSON.stringify({ value : props.supplier})));

     const success = ref(false);

     const errors = reactive({
       value : {}
     });


     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         mySupplier.value = JSON.parse(JSON.stringify(props.supplier))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_PRODUCT_SUPPLIER,mySupplier.value).then(() => {

         errors.value = [];
         success.value = true;
        
         setTimeout(function(){
           submitButton.value?.removeAttribute("data-kt-indicator");
           const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
           closeModal.click();
           emit('input')
         },1000)

       }).catch((response) => {
         submitButton.value?.removeAttribute("data-kt-indicator");
         errors.value = response.data.errors;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

       })
     }

     return {
       id,
       props,
       errors,
       store,
       submitForm,
       mySupplier,
       success
     }
   }
 }
