
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import { Actions } from "@/store/enums/ProductSuppliersEnums.ts";
import { Actions as SupplierActions } from "@/store/enums/SupplierEnums.ts";
import AddProductSupplier from "@/views/products/components/edit/product_suppliers/AddProductSupplier.vue";
import EditProductSupplier from "@/views/products/components/edit/product_suppliers/EditProductSupplier.vue";
import moment from 'moment'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
export default {
  name : 'ProductSuppliers',
  components : {
    Table,
    AddProductSupplier,
    EditProductSupplier,
    DeleteRecord
  },
  props : ["id"],
  setup(props){
    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(SupplierActions.ALL_SUPPLIERS);
    })

    const columns = [
      {name : 'name', title : 'Supplier', sortable : true, sort : 'asc', searchable : true},
      {name : 'address', title : 'Supplier Address', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];


    return {
      columns,
      props,
      actions,
      store,
      moment
    }
  }
}
