
import { onMounted } from 'vue';
import ComponentDetails from '@/views/products/components/edit/ComponentDetails.vue'
import AgreedPrices from '@/views/products/components/edit/AgreedPrices.vue'
import ProductSuppliers from '@/views/products/components/edit/ProductSuppliers.vue'
import ComponentActivities from '@/views/products/components/edit/ComponentActivities.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'ComponentEdit',
  components : {
    ComponentDetails,
    AgreedPrices,
    ProductSuppliers,
    ComponentActivities
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "Edit Component",to : "/products/component/edit/"+route.params.id}
      ]);
    });



    return {
      route
    }
  }
}
