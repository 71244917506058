
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import { Actions } from "@/store/enums/AgreedPricesEnums.ts";
import { Actions as SupplierActions } from "@/store/enums/SupplierEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import AddAgreedPrice from "@/views/products/components/edit/agreed_prices/AddAgreedPrice.vue";
import EditAgreedPrice from "@/views/products/components/edit/agreed_prices/EditAgreedPrice.vue";
import moment from 'moment'
import numeral from 'numeral'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
export default {
  name : 'AgreedPrices',
  components : {
    Table,
    AddAgreedPrice,
    EditAgreedPrice,
    DeleteRecord
  },
  props : ["id"],
  setup(props){
    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(SupplierActions.ALL_SUPPLIERS);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    })

    const columns = [
      {name : 'supplier', title : 'Supplier', sortable : true, sort : 'asc', searchable : true},
      {name : 'cost', title : 'Cost', sortable : true, sort : null, searchable : true},
      {name : 'currency', title : 'Currency', sortable : true, sort : null, searchable : true},
      {name : 'minOrderQty', title : 'Min. Order Qty. (cases)', sortable : true, sort : null, searchable : true},
      {name : 'exWorks', title : 'Ex Works', sortable : true, sort : null, searchable : false},
      {name : 'contactReference', title : 'Contact Ref.', sortable : true, sort : null, searchable : true},
      {name : 'startDate', title : 'Start Date', sortable : true, sort : null, searchable : true},
      {name : 'endDate', title : 'End Date', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];


    return {
      columns,
      props,
      actions,
      store,
      moment,
      numeral
    }
  }
}
