<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Add Price
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Agreed Price</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added price!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Supplier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.supplier_id}" v-model="formData.supplier_id" @change="formData.supplier_id = $event.target.value">
                <option selected disabled value="null">Select Supplier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myAllSuppliers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.supplier_id">{{errors.value && errors.value.supplier_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 mb-3 col-lg-6 mb-lg-0">
              <label class="col-form-label required fw-bold fs-6">Cost</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.cost}" v-model="formData.cost">
              <span class="text-danger" v-if="errors.value && errors.value.cost">{{errors.value && errors.value.cost[0]}}</span>
            </div>
            <div class="col-12 col-lg-6">
              <label class="col-form-label required fw-bold fs-6">Min Order Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.minOrderQty}" v-model="formData.minOrderQty">
              <span class="text-danger" v-if="errors.value && errors.value.minOrderQty">{{errors.value && errors.value.minOrderQty[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Ex Works</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="formData.exWorks">
                <label class="form-check-label" for="flexSwitchCheckChecked"></label>
              </div>
              <span class="text-danger" v-if="errors.value && errors.value.exWorks">{{errors.value && errors.value.exWorks[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Currency</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="formData.currency_id" @change="formData.currency_id = $event.target.value">
                <option selected disabled value="null">Select Currency</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Contact Reference</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.contactReference}" v-model="formData.contactReference">
              <span class="text-danger" v-if="errors.value && errors.value.contactReference">{{errors.value && errors.value.contactReference[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 mb-3 col-lg-6 mb-lg-0">
              <label class="col-form-label fw-bold fs-6">Start Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.startDate}" v-model="formData.startDate">
              <span class="text-danger" v-if="errors.value && errors.value.startDate">{{errors.value && errors.value.startDate[0]}}</span>
            </div>
            <div class="col-12 mb-3 col-lg-6 mb-lg-0">
              <label class="col-form-label fw-bold fs-6">End Date</label>
              <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.endDate}" v-model="formData.endDate">
              <span class="text-danger" v-if="errors.value && errors.value.endDate">{{errors.value && errors.value.endDate[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal">Close</button>
          <button
            type="submit"
            id="addAPSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/AgreedPricesEnums.ts";

import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
 export default {
   name : 'AddAgreedPrice',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       product_id : props.id,
       supplier_id : null,
       cost : null,
       minOrderQty : null,
       exWorks : false,
       currency_id : null,
       contactReference : '',
       startDate : null,
       endDate : null
     })

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.supplier_id = null;
         formData.cost = null;
         formData.minOrderQty = null;
         formData.exWorks = false;
         formData.currency_id = null;
         formData.contactReference = '';
         formData.startDate = null;
         formData.endDate = null;
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addAPSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_AGREED_PRICE,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){

           const closeModal = document.getElementById('close-modal') as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
</script>
