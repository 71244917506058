<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModalproductsupplieradd">
    Add Supplier
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModalproductsupplieradd" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Product Supplier</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added product supplier!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Supplier</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.supplier_id}" v-model="formData.supplier_id" @change="formData.supplier_id = $event.target.value">
                <option selected disabled value="null">Select Supplier</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myAllSuppliers" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.supplier_id">{{errors.value && errors.value.supplier_id[0]}}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-ProductSupplierSubmitBtn">Close</button>
          <button
            type="submit"
            id="addProductSupplierSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/ProductSuppliersEnums.ts";

import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
 export default {
   name : 'AddProductSupplier',
   props : ["id"],
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const success = ref(false)
     const errors = reactive({
       value : {}
     });


     const formData = reactive({
       product_id : props.id,
       supplier_id : null
     })

     onMounted(() => {



       const myModalEl = document.getElementById('exampleModal') as any
       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         errors.value = [];
         formData.supplier_id = null;
       })
     })


     const submitForm = () => {
       const submitButton = ref<HTMLElement | null>(document.getElementById('addProductSupplierSubmitBtn') as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.ADD_PRODUCT_SUPPLIER,formData).then(() => {
         errors.value = [];
         success.value = true;
         setTimeout(function(){

           const closeModal = document.getElementById('close-modal-ProductSupplierSubmitBtn') as HTMLElement;
           closeModal.click();
           emit('input');
           submitButton.value?.removeAttribute("data-kt-indicator");
         },1000)
       }).catch((response) => {
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

         errors.value = response.data.errors;

         submitButton.value?.removeAttribute("data-kt-indicator");

       })
     }

     return {
       props,
       formData,
       errors,
       store,
       submitForm,
       success
     }
   }
 }
</script>
