<template>
  <div class="pt-10">



    <Table :columns="columns" ref="table" :action="actions.SEARCH_PRODUCT_SUPPLIERS" :id="Number(props.id)">


      <template v-slot:extra="slotProps">

        <div class="mb-5">

            <AddProductSupplier :id="props.id" @input="slotProps.search()"/>

        </div>

      </template>



      <template v-slot:actions="slotProps">

        <div>

          <div class="btn-group" role="group">

            <EditProductSupplier :supplier="slotProps.row" @input="slotProps.search()"/>

            <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_PRODUCT_SUPPLIER" :callback="slotProps.search"/>

          </div>

        </div>

      </template>

    </Table>

  </div>
</template>
<script lang="ts">
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import { Actions } from "@/store/enums/ProductSuppliersEnums.ts";
import { Actions as SupplierActions } from "@/store/enums/SupplierEnums.ts";
import AddProductSupplier from "@/views/products/components/edit/product_suppliers/AddProductSupplier.vue";
import EditProductSupplier from "@/views/products/components/edit/product_suppliers/EditProductSupplier.vue";
import moment from 'moment'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
export default {
  name : 'ProductSuppliers',
  components : {
    Table,
    AddProductSupplier,
    EditProductSupplier,
    DeleteRecord
  },
  props : ["id"],
  setup(props){
    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(SupplierActions.ALL_SUPPLIERS);
    })

    const columns = [
      {name : 'name', title : 'Supplier', sortable : true, sort : 'asc', searchable : true},
      {name : 'address', title : 'Supplier Address', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];


    return {
      columns,
      props,
      actions,
      store,
      moment
    }
  }
}
</script>
