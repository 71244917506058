
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ProductEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import { useRouter } from "vue-router";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'ComponentDetails',
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    const attachment = ref(null)

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      store.dispatch(Actions.GET_COMPONENT,{id : props.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

        attachment.value = store.getters.currentComponent.attachment

        store.getters.currentComponent.attachment = null

      });
      store.dispatch(Actions.GET_PRODUCT_CATEGORIES);
      store.dispatch(Actions.GET_DEVICE_CLASSIFICATION);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    });


    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_PRODUCT,store.getters.currentComponent).then(() => {
        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        setTimeout(() => {
          router.push('/products/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    const fileAdded = (e) => {

      store.getters.currentComponent.attachment = e.target.files[0];

    }

    const downloadDocument = () => {

      store.dispatch(Actions.DOWNLOAD_DOCUMENT, {
        path: attachment.value
      })
          .then(res => {
            const contentType = res.headers['content-type'];

            const newBlob = new Blob([res.data], {type: contentType})

            const data = window.URL.createObjectURL(newBlob)
            const link = document.createElement('a')

            link.href = data
            link.setAttribute('download', 'document');
            link.click()

            setTimeout(function () {

              window.URL.revokeObjectURL(data)
            }, 100)
          })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors,
      fileAdded,
      downloadDocument,
      attachment
    }
  }
}
