<template>
  <div class="pt-10">

    <div class="row mb-5">
      <div class="col-12 mb-5" v-show="success">
        <div class="row mb-0">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully changed component!
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Product Details</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Product Type</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.product_type_id}" value="COMPONENT" :readonly="true">
          <span class="text-danger" v-if="errors.value && errors.value.product_type_id">{{errors.value && errors.value.product_type_id[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Product Code</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.code}" v-model="store.getters.currentComponent.code" placeholder="Enter product code">
          <span class="text-danger" v-if="errors.value && errors.value.code">{{errors.value && errors.value.code[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">UDI</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.udi}" v-model="store.getters.currentComponent.udi" placeholder="Enter UDI">
          <span class="text-danger" v-if="errors.value && errors.value.udi">{{errors.value && errors.value.udi[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Name</label>
          <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.name}" v-model="store.getters.currentComponent.name" placeholder="Enter name">
          <span class="text-danger" v-if="errors.value && errors.value.name">{{errors.value && errors.value.name[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Description</label>
          <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.description}" v-model="store.getters.currentComponent.description" placeholder="Enter description"></textarea>
          <span class="text-danger" v-if="errors.value && errors.value.description">{{errors.value && errors.value.description[0]}}</span>
        </div>
        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Category</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.product_category_id}" v-model="store.getters.currentComponent.product_category_id">
            <option selected disabled>Select Category</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myProductCategories" :key="key">{{item.name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.product_category_id">{{errors.value && errors.value.product_category_id[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Device Classification</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.device_classification_id}" v-model="store.getters.currentComponent.device_classification_id">
            <option selected disabled>Select Please</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myClassificationCategoies" :key="key">{{item.name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.device_classification_id">{{errors.value && errors.value.device_classification_id[0]}}</span>
        </div>
        <div class="form-group">
          <label class="col-form-label fw-bold fs-6">Obsolete</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="store.getters.currentComponent.obsolete">
            <label class="form-check-label" for="flexSwitchCheckChecked"></label>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Stock Control</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Re-Order Level</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reOrderLevel}" v-model="store.getters.currentComponent.reOrderLevel" placeholder="Enter re-order level">
          <span class="text-danger" v-if="errors.value && errors.value.reOrderLevel">{{errors.value && errors.value.reOrderLevel[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Re-Order Quantity</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reOrderQty}" v-model="store.getters.currentComponent.reOrderQty" placeholder="Enter re-order quantity">
          <span class="text-danger" v-if="errors.value && errors.value.reOrderQty">{{errors.value && errors.value.reOrderQty[0]}}</span>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Packaging</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Items Per Carton</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.itemsPerCarton}" v-model="store.getters.currentComponent.itemsPerCarton" placeholder="Enter items per carton">
          <span class="text-danger" v-if="errors.value && errors.value.itemsPerCarton">{{errors.value && errors.value.itemsPerCarton[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Cost / Carton</label>
          <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.costPerCarton}" v-model="store.getters.currentComponent.costPerCarton" placeholder="Enter cost per carton">
          <span class="text-danger" v-if="errors.value && errors.value.costPerCarton">{{errors.value && errors.value.costPerCarton[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Currency</label>
          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="store.getters.currentComponent.currency_id">
            <option :value="null" selected disabled>Select Currency</option>
            <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
          </select>
          <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
        </div>
      </div>

      <div class="col-12 col-lg-3 mb-3 mb-lg-0">
        <div class="mb-3 pb-3 border-bottom">
          <h3 class="fw-400">Notes</h3>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label fw-bold fs-6">Note</label>
          <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="store.getters.currentComponent.note" placeholder="Enter note"></textarea>
          <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
        </div>

        <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Attachment</label>
              <input type="file" id="file" class="form-control d-none" :class="{'is-invalid' : errors.value && errors.value.attachment}" @change="fileAdded" />
              <label for="file" class="d-block p-3 bg-light border border-dashed rounded border-3 text-primary fw-bolder cursor-pointer text-center">

                <div class="mb-4">
                  {{ store.getters.currentComponent.attachment ? store.getters.currentComponent.attachment.name : (attachment ? attachment : 'Select File Please') }}
                </div>  
                
                <div class="fw-bolder text-dark">Browse Files</div>
                
              </label>
              <span class="text-danger" v-if="errors.value && errors.value.attachment">{{errors.value && errors.value.attachment[0]}}</span>
        </div>

        <a href="javascript:;" v-if="attachment" class="text-success" @click="downloadDocument">See Attachment</a>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-end m-0 pt-5 border-top">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder mt-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ProductEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import { useRouter } from "vue-router";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'ComponentDetails',
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    const attachment = ref(null)

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      store.dispatch(Actions.GET_COMPONENT,{id : props.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

        attachment.value = store.getters.currentComponent.attachment

        store.getters.currentComponent.attachment = null

      });
      store.dispatch(Actions.GET_PRODUCT_CATEGORIES);
      store.dispatch(Actions.GET_DEVICE_CLASSIFICATION);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    });


    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_PRODUCT,store.getters.currentComponent).then(() => {
        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        setTimeout(() => {
          router.push('/products/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    const fileAdded = (e) => {

      store.getters.currentComponent.attachment = e.target.files[0];

    }

    const downloadDocument = () => {

      store.dispatch(Actions.DOWNLOAD_DOCUMENT, {
        path: attachment.value
      })
          .then(res => {
            const contentType = res.headers['content-type'];

            const newBlob = new Blob([res.data], {type: contentType})

            const data = window.URL.createObjectURL(newBlob)
            const link = document.createElement('a')

            link.href = data
            link.setAttribute('download', 'document');
            link.click()

            setTimeout(function () {

              window.URL.revokeObjectURL(data)
            }, 100)
          })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors,
      fileAdded,
      downloadDocument,
      attachment
    }
  }
}
</script>
