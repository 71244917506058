<template>
  <div class="pt-10">



    <Table :columns="columns" ref="table" :action="actions.SEARCH_AGREED_PRICES" :id="Number(props.id)">


      <template v-slot:extra="slotProps">

        <div class="mb-5">

            <AddAgreedPrice :id="props.id" @input="slotProps.search()"/>

        </div>

      </template>

      <template v-slot:cost="slotProps">
        <div>{{ numeral(slotProps.row.cost).format('0,0.00000') }}</div>
      </template>


      <template v-slot:exWorks="slotProps">
        <div>{{ slotProps.row.exWorks === true ? 'Yes' : 'No' }}</div>
      </template>

      <template v-slot:startDate="slotProps">
        <div>{{ slotProps.row.startDate !== null ? moment(new Date(slotProps.row.startDate)).format('DD MMM yyyy') : '' }}</div>
      </template>

      <template v-slot:endDate="slotProps">
        <div>{{ slotProps.row.endDate !== null ? moment(new Date(slotProps.row.endDate)).format('DD MMM yyyy') : '' }}</div>
      </template>

      <template v-slot:actions="slotProps">

        <div>

          <div class="btn-group" role="group">

            <EditAgreedPrice :agreedprice="slotProps.row" @input="slotProps.search()"/>

            <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_AGREED_PRICE" :callback="slotProps.search"/>

          </div>

        </div>

      </template>

    </Table>

  </div>
</template>
<script lang="ts">
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import { Actions } from "@/store/enums/AgreedPricesEnums.ts";
import { Actions as SupplierActions } from "@/store/enums/SupplierEnums.ts";
import { Actions as CurrencyActions } from "@/store/enums/CurrencyEnums.ts";
import AddAgreedPrice from "@/views/products/components/edit/agreed_prices/AddAgreedPrice.vue";
import EditAgreedPrice from "@/views/products/components/edit/agreed_prices/EditAgreedPrice.vue";
import moment from 'moment'
import numeral from 'numeral'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
export default {
  name : 'AgreedPrices',
  components : {
    Table,
    AddAgreedPrice,
    EditAgreedPrice,
    DeleteRecord
  },
  props : ["id"],
  setup(props){
    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(SupplierActions.ALL_SUPPLIERS);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    })

    const columns = [
      {name : 'supplier', title : 'Supplier', sortable : true, sort : 'asc', searchable : true},
      {name : 'cost', title : 'Cost', sortable : true, sort : null, searchable : true},
      {name : 'currency', title : 'Currency', sortable : true, sort : null, searchable : true},
      {name : 'minOrderQty', title : 'Min. Order Qty. (cases)', sortable : true, sort : null, searchable : true},
      {name : 'exWorks', title : 'Ex Works', sortable : true, sort : null, searchable : false},
      {name : 'contactReference', title : 'Contact Ref.', sortable : true, sort : null, searchable : true},
      {name : 'startDate', title : 'Start Date', sortable : true, sort : null, searchable : true},
      {name : 'endDate', title : 'End Date', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];


    return {
      columns,
      props,
      actions,
      store,
      moment,
      numeral
    }
  }
}
</script>
