<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Edit Component</h3>
        </div>
        <!--end::Card title-->
      </div>

      <!--begin::Card body-->
      <div class="card-body p-9">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="activity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="false">Activity</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="prices-tab" data-bs-toggle="tab" data-bs-target="#prices" type="button" role="tab" aria-controls="prices" aria-selected="false">Agreed Prices</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="suppliers-tab" data-bs-toggle="tab" data-bs-target="#suppliers" type="button" role="tab" aria-controls="suppliers" aria-selected="false">Suppliers</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
            <ComponentDetails :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
            <ComponentActivities :id="route.params.id"/>
          </div>
          <div class="tab-pane fade" id="prices" role="tabpanel" aria-labelledby="prices-tab">
            <AgreedPrices :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="suppliers" role="tabpanel" aria-labelledby="suppliers-tab">
            <ProductSuppliers :id="route.params.id" />
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script lang="ts">
import { onMounted } from 'vue';
import ComponentDetails from '@/views/products/components/edit/ComponentDetails.vue'
import AgreedPrices from '@/views/products/components/edit/AgreedPrices.vue'
import ProductSuppliers from '@/views/products/components/edit/ProductSuppliers.vue'
import ComponentActivities from '@/views/products/components/edit/ComponentActivities.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'ComponentEdit',
  components : {
    ComponentDetails,
    AgreedPrices,
    ProductSuppliers,
    ComponentActivities
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Products",[
        {title : "Products",to : "/products/list"},
        {title : "Edit Component",to : "/products/component/edit/"+route.params.id}
      ]);
    });



    return {
      route
    }
  }
}
</script>
